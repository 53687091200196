import { Layout, UserContext } from '../components/Layout'
import React from 'react'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { Grid, Cell } from 'baseui/layout-grid'
import { HorizontalLine } from '../components/Line'
import { useStyletron } from 'baseui'
import { ListNavItem, ListNav } from '../components/ListNav'
import { getHazardList, HazardPagesListResponse, LiteHazardPagesResponseItem } from '../api/cms'
import { slugify, handleErrorFromAPI, loginRedirect } from '../utils'
import { ErrorNotification } from '../components/Notification'

export const HazardList = () => {
  const [hazards, setHazards] = React.useState<LiteHazardPagesResponseItem[] | null>(null)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const { token, setUserContext } = React.useContext(UserContext)

  React.useEffect(() => {
    if (token) {
      getHazardList(token)
        .then((response: HazardPagesListResponse) => {
          if (response.meta.total_count > 0) {
            setHazards(response.items)
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    } else {
      loginRedirect()
    }
  }, [token, setUserContext])

  const listItems = hazards
    ? hazards.map(hazard => (
        <ListNavItem url={`/hazard?id=${hazard.id}`} key={hazard.id} testId={slugify(hazard.title)}>
          {hazard.title}
        </ListNavItem>
      ))
    : null

  return (
    <>
      <ErrorNotification hasError={loadingErrorActive} />
      <ListNav>{listItems}</ListNav>
    </>
  )
}

export const HazardListPage = () => {
  const [css] = useStyletron()
  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <HeadingLevel>
            <Heading data-testid="hazard-list-heading">Information on hazards</Heading>
            <HorizontalLine
              className={css({
                marginBottom: '8px'
              })}
            />
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <HazardList />
        </Cell>
      </Grid>
    </>
  )
}

const LayoutHazardListPage = () => {
  return (
    <Layout searchBarOpen={true}>
      <HazardListPage />
    </Layout>
  )
}

export default LayoutHazardListPage
